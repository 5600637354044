import React from "react"
import createSagaMiddleware from "redux-saga"
import { createStore, applyMiddleware, compose } from "redux"
import { Provider } from "react-redux"
import rootSaga, { rootReducer } from "./index"

export default ({ element }) => {
  const windowGlobal = typeof window !== "undefined" ? window : null

  const sagaMiddleware = createSagaMiddleware(rootSaga)
  const composeEnhancers =
    windowGlobal !== null
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
      : compose
  const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(sagaMiddleware))
  )

  sagaMiddleware.run(rootSaga)

  return <Provider store={store}>{element}</Provider>
}
