// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cgu-js": () => import("./../src/pages/cgu.js" /* webpackChunkName: "component---src-pages-cgu-js" */),
  "component---src-pages-download-js": () => import("./../src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-fonctionnalite-js": () => import("./../src/pages/fonctionnalite.js" /* webpackChunkName: "component---src-pages-fonctionnalite-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-old-js": () => import("./../src/pages/index-old.js" /* webpackChunkName: "component---src-pages-index-old-js" */),
  "component---src-pages-tarifs-js": () => import("./../src/pages/tarifs.js" /* webpackChunkName: "component---src-pages-tarifs-js" */)
}

