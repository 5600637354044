import { createActions, createReducer } from "reduxsauce"
import { put, takeLatest, call } from "redux-saga/effects"

export const { Types, Creators } = createActions({
  changeTheme: ["payload"],
})

const INITIAL_STATE = {
  darkMode: false,
}

function* change(action) {
  try {
    yield put(Creators.changeTheme({ ...action.payload }))
  } catch (e) {}
}

export default function* mySaga() {
  //yield takeLatest(Types.CHANGE_THEME,change);
}

// Reducers Handler :

const indexResponse = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.payload,
  }
}

export const themeReducer = createReducer(INITIAL_STATE, {
  [Types.CHANGE_THEME]: indexResponse,
})
