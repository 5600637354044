import { combineReducers } from "redux"
import { all, fork } from "redux-saga/effects"
import theme, { themeReducer } from "./theme"

export default function* rootSaga() {
  yield all([fork(theme)])
}

export const rootReducer = combineReducers({
  theme: themeReducer,
})
